import React, { useEffect } from 'react';
import '../styles.css';

const BlobBlur = () => {

  useEffect(() => {
    const blob = document.getElementById("blob");
    const maxHeight = document.documentElement.scrollHeight - 500; // Set a custom limit for the blob's movement

    const handlePointerMove = (event) => {
      const { clientX, clientY } = event;
      const newTop = Math.min(clientY + window.scrollY, maxHeight);

      blob.animate({
        left: `${clientX}px`,
        top: `${newTop}px`
      }, { duration: 3000, fill: "forwards" });
    };

    window.addEventListener('pointermove', handlePointerMove);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, []);

  return (
    <>
      <div id="blob"></div>
      <div id="blur"></div>
    </>
  );
};

export default BlobBlur;
