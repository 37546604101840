import React, { useState } from 'react';
import '../styles.css';
import UISeperator from '../components/UISeperator';
import MiniUISeperator from '../components/miniUISeperator';
import FoldableSquare from '../components/foldableSquare';
import GivingBack from '../components/givingBack';
import HomePageFAQ from '../components/homepageFAQ';
import HomePagePricing from '../components/homepagePricing';
import BlobBlur from '../components/blobBlur';
import HomepageFeatures from '../components/homepagefeatures';
import { Helmet } from 'react-helmet';

function Home() {
    const [inputValue, setInputValue] = useState('');

    const handleAnalysis = () => {
        if (!inputValue) {
        return; // Don't redirect if input is empty
        }

        // Encode the URL parameter
        const encodedUrl = encodeURIComponent(inputValue);
        
        // Construct the new URL with the encoded parameter
        // This will redirect to /analysis with the URL parameter
        window.location.href = `/tools/websitegrader?url=${encodedUrl}`;
    };


  return (
        <body className='homepage'> 
            <Helmet>
                <title>Calucid - Premium Website Design</title>
                <meta name="description" content="Boost your business with a professional, SEO-optimized, and custom website designed to grow your online presence. Our professional web design team delivers responsive, high-converting websites in just 7 days. Let’s build a website that drives results and supports your business goals!" />
                <meta name="keywords" content="website design, web design company, custom web development, responsive web design, ecommerce website design, UI/UX design, web development services, digital marketing, website maintenance, graphic design, SEO-friendly web design, mobile-friendly websites, creative web design, web design agency, small business web design, web design near me, [Your City] web design company, professional website design, custom website solutions"/>
            </Helmet>
            <br>
            
            </br>
            <div>
                <div>
                    <h1 style={{ color: "rgb(150,150,150)" }} >Don't Settle.</h1>
                    <h1>Buy your Dream Website Today.</h1>
                </div>
                {/* <div>
                    <h1>Stop Hoping for Results.</h1>
                    <h1>Engineer Websites That Drive Revenue.</h1>
                </div> */}
                <br></br><br></br>
                <div>
                    <h3>Is Your Website Working?</h3>
                    <div className='inputBox'>
                    <input 
                        placeholder="https://example.com"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleAnalysis();
                        }
                        }}
                    />
                    <button onClick={handleAnalysis}>Start My Analysis</button>
                    </div>
                    <h3>Get your answer in minutes.</h3>
                </div>
            </div>
            
            <UISeperator></UISeperator>
            <FoldableSquare></FoldableSquare>
            <h1 className='sglow'>Premium Website Design</h1>
            <br></br>
            {/* <UISeperator></UISeperator>
            <div>
                <h1>Award Winning Web Design.</h1>
                <div></div>
            </div> */}
            <GivingBack></GivingBack>
            <HomepageFeatures></HomepageFeatures>
            <UISeperator></UISeperator>
            <h1>Results First, Pay Later</h1>
            <MiniUISeperator></MiniUISeperator>
            <h2 className='homepagetext'>At Calucid, we believe in earning your trust first. That’s why we’ll build your website for free—no upfront costs, no obligations. Once you review the final product, if it’s not exactly what you want, you pay nothing. But if you love it (and we’re confident you will), we’ll launch your site online, and only then will you start paying—after we’ve created your website. </h2>
            <UISeperator></UISeperator>
            <HomePagePricing></HomePagePricing>
            <UISeperator></UISeperator>
            <HomePageFAQ></HomePageFAQ>
            <UISeperator></UISeperator>
            <h1>Contact Us</h1>
            <h3>Send us an email: <a style={{color:'cyan'}} href="mailto:hello@calucid.com">hello@calucid.com</a></h3>




            <div style={{ height: "100px" }}></div>
            <BlobBlur></BlobBlur>

        </body>
        
    );
}

export default Home;
