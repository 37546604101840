import React, { useEffect, useRef } from 'react';

function FoldableSquare() {
  // Create a ref to store the square element
  const squareRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (squareRef.current) {
        // Get the scroll position
        const scrollValue = window.scrollY;

        // Set a default threshold
        let threshold = 600;

        // Adjust the threshold for devices with a viewport smaller than 738px
        if (window.innerWidth < 738) {
          threshold = 120; // Reduce the threshold for smaller devices
        }

        let foldAngle = 0;

        if (scrollValue > threshold) {
          // Calculate the fold angle based on the scroll position
          foldAngle = Math.max((-(scrollValue - threshold) / 10), -45); // Limit fold angle to a maximum of -45 degrees
        }

        // Apply the folding effect using CSS transform
        squareRef.current.style.transform = `perspective(1000px) rotateX(${foldAngle}deg)`;
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  return (
    <div className="squareContainer">
      <div className="square" ref={squareRef}>
        <div className="squareFrame"></div>
      </div>
    </div>
  );
}

export default FoldableSquare;
