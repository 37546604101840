import React from 'react';
import ContactForm from '../components/contactForm';
import '../styles.css';
import MiniUISeperator from '../components/miniUISeperator';
import { Helmet } from 'react-helmet';


function Contact() {
  return (
    <div className='homepage'>
    <Helmet>
        <title>Contact Us - Calucid</title>
        <meta name="description" content="How can we help? Do you have a question or are you interested in working with us? Just fill out the form fields below. We'd love to talk about." />
        <link rel="canonical" href="https://www.calucid.com/contact" />
    </Helmet>
    <h1>How can I help?</h1>
    <h2>Do you have a question or are you interested in working with us?</h2>
    <h2>Just fill out the form fields below.</h2>
    <ContactForm></ContactForm>
    <div className='contactemailsection'>
      <h1>Or send us an email...</h1>
      <MiniUISeperator></MiniUISeperator>
      <h2><a style={{color:'white'}} href="mailto:hello@calucid.com">hello@calucid.com</a></h2>
      </div>
    <div className='contactsocialssection'>
      <h1>Or conect with us on...</h1>
      <MiniUISeperator></MiniUISeperator>
      <div>
      <div>
      <a style={{backgroundImage:'url("/images/xiconblack.png")'}} href='https://x.com/calucidofficial' className='socialsicon'></a>
      <a style={{backgroundImage:'url("/images/black-instagram-icon.webp")'}} href='https://instagram.com/calucidofficial' className='socialsicon'></a>
      <a style={{backgroundImage:'url("https://www.svgrepo.com/show/360559/medium-circle.svg")'}} className='socialsicon' href='https://medium.com/@calucid'></a>

      </div>
  

      </div>
    </div>
    </div>

  );
}

export default Contact;
