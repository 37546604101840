import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDz4KeNIcdmLwRuKLOes-7NN7wBkLJAMYQ",
  authDomain: "calucid-6d287.firebaseapp.com",
  projectId: "calucid-6d287",
  storageBucket: "calucid-6d287.appspot.com",
  messagingSenderId: "526773370648",
  appId: "1:526773370648:web:7ecf61233514bb74b1286d",
  measurementId: "G-JLHGWWC373"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ContactForm = () => {
  const [formData, setFormData] = useState({
    type: '',
    firstName: '',
    lastName: '',
    message: '',
    timestamp: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if all fields are filled
    if (formData.type && formData.firstName && formData.lastName && formData.message) {
      setIsSubmitting(true);
      setError(null);

      try {
        // Add timestamp to the form data
        const submissionData = {
          ...formData,
          timestamp: new Date().toISOString()
        };

        // Add document to Firestore
        const docRef = await addDoc(collection(db, 'contactSubmissions'), submissionData);
        
        console.log('Document written with ID:', docRef.id);
        setSubmitted(true);
      } catch (err) {
        console.error('Error adding document:', err);
        setError('There was an error submitting your form. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setError('Please fill in all fields');
    }
  };

  return (
    <div className="contact-form-container">
      {submitted ? (
        <div className="success-message p-4 bg-green-100 text-green-700 rounded">
          <h2>Form submitted successfully! Thank you for your submission.</h2>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
              className="form-control select-type w-full p-2 border rounded mb-4"
            >
              <option value="">Select type</option>
              <option value="Inquiry">Inquiry</option>
              <option value="Feedback">Feedback</option>
              <option value="Support">Support</option>
            </select>
          </div>
          <div className="form-group flex gap-4 mb-4">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
              className="form-control input-first-name flex-1 p-2 border rounded"
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className="form-control input-last-name flex-1 p-2 border rounded"
            />
          </div>
          <div className="form-group mb-4">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              required
              className="textarea-message w-full p-2 border rounded"
              style={{ height: '150px' }}
            />
          </div>
          <p className="text-sm text-gray-600 mb-4">
            By clicking the button below, you consent for Calucid and partners to use automated technology, including pre-recorded messages, cell phones and texts, and email to contact you at the number and email address provided. This includes if the number is currently on any Do Not Call Lists. This consent is not required to make a purchase.
          </p>
          {error && (
            <div className="error-message text-red-600 mb-4">
              {error}
            </div>
          )}
          <button 
            type="submit" 
            className="submit-btn bg-blue-600 text-white px-6 py-2 rounded hover:bg-blue-700 disabled:bg-blue-300"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;