import React from 'react';
import BlogCard from '../components/blogCard';
import '../styles.css';
import { Helmet } from 'react-helmet';



function Tool() {
  return (
    <body className='homepage'>
      <Helmet>
          <title>Calucid's Free Website Tools</title>
          <meta name="description" content="Use our free web tools to analyze and improve your website." />
          <link rel="canonical" href="https://www.calucid.com/tools" />
      </Helmet>
      <br></br><br></br><br></br>
      <h1>Calucid's Free Online Tools</h1>
      <br></br><br></br>
      <div className='blogcontainer'>
        <BlogCard link="/tools/websitegrader" image="/images/computer_picture.png" title="Website Design Grader"></BlogCard>
        <BlogCard link="/tools/seoaudit" image="/images/world-network.webp" title="SEO Audit Tool"></BlogCard>
        {/* <BlogCard link="" image="/images/roadmap.jpg" title="Online Business Roadmap"></BlogCard> */}
        
      </div>
      <br></br><br></br><br></br><br></br>
      <br></br><br></br><br></br><br></br>
      <br></br><br></br><br></br><br></br>
      <br></br><br></br><br></br><br></br>
    </body>
  );
}

export default Tool;
