import React from 'react';
import '../styles.css';


function MiniUISeperator(){
  return (
    <div className='miniUISeperator'>
    </div>
  );
}

export default MiniUISeperator;