import React from 'react';
import GivingBack from '../components/givingBack';
import UISeperator from '../components/UISeperator';
import MiniUISeperator from '../components/miniUISeperator';
import BlobBlur from '../components/blobBlur';
import { Helmet } from 'react-helmet';


function Confirmation() {
  return (
    <body className='homepage'>
      <br></br><br></br>
      <h1>Form Submitted ✓</h1>
    <h2 >
        Your response has been received successfully!
    </h2>
    <br></br><br></br>
    <h2 >
    We will email you within the next 7 days once your website is complete.
    </h2>
    <br></br><br></br><br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br><br></br><br></br>
    <br></br><br></br><br></br><br></br><br></br><br></br>

    <h1>In The Meantime...</h1>
    <MiniUISeperator></MiniUISeperator>
    <h2 className='aboutpagetitle'> <a style={{color:'#006fff', textDecoration:'none'}}  href="/blog" >Checkout our business blog </a></h2>
    <h2>-Or-</h2>
    <h2 className='aboutpagetitle'> <a  style={{color:'#006fff', textDecoration:'none'}}  href="/" >Return to our homepage </a></h2>


      <br></br><br></br><br></br><br></br><br></br><br></br>
      <BlobBlur></BlobBlur>
    </body>
  );
}

export default Confirmation;
