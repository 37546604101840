import React, { useState, useEffect, useRef } from 'react';
import '../styles.css';

function HomePageFAQ() {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqRef = useRef([]);

  const togglePanel = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
      rootMargin: '300px 0px 300px 0px' // Adjust this to control when the effect triggers
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          entry.target.classList.remove('hidden');
        } else {
          entry.target.classList.remove('visible');
          entry.target.classList.add('hidden');
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    faqRef.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      faqRef.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  const faqItems = [
    {
      question: "Why Choose Us?",
      answer: "At Calucid, We break free from the ordinary. While others deliver bland websites resembling newspapers and magazines, we offer fluid and creative designs that set you apart. Partner with Calucid for a high-quality, hassle-free website that truly supports your business goals.",
    },
    {
      question: "Do I Really Need a Website?",
      answer: "Absolutely! A website is essential in today’s digital-first world, acting as your online storefront to reach audiences you’d miss otherwise. A professionally designed site from Calucid not only builds credibility but also helps you stay visible in search results, drive leads, and grow your business effortlessly. We make getting started easy and impactful.",
    },
    {
      question: "Isn’t Having a Website Expensive?",
      answer: "Not with Calucid! For just $49.99 a month, we will create and manage a high-quality website designed to fit your business needs. Think of it as a small investment with big returns, boosting your online visibility, customer reach, and ultimately, your revenue. We’re here to make sure you get top value without breaking the bank.",
    },
    {
      question: "Will a Website Take a Lot of Time to Manage?",
      answer: "Not at all! At Calucid, we handle everything for you. From updates to security checks, our team manages your site completely, ensuring it’s always up-to-date, optimized, and secure. With us, you’ll never have to worry about the technical side of things, letting you focus on your business while we keep your website running smoothly.",
    },
    {
      question: "What Is Our Results First, Pay Later?",
      answer: "Our Results First, Pay Later policy means that we build your entire website upfront at no cost to you, with no obligations. You’ll have the chance to review the finished product before committing, and if it’s not exactly what you want, you owe us nothing. If you’re thrilled with the result (and we’re confident you will be), we’ll launch your site online, and only then will payments begin—ensuring you love what you buy.",
    },
  ];

  return (
    <div className="FAQContainer">
      <h1 className="FAQ-title">
        Frequently Asked Questions
      </h1>
      {faqItems.map((item, index) => (
        <div 
          key={index} 
          className="FAQ-item slide-effect"
          ref={(el) => (faqRef.current[index + 1] = el)}
        >
          <button
            className={`FAQ ${activeIndex === index ? 'active1' : ''}`}
            onClick={() => togglePanel(index)}
          >
            {item.question}
          </button>
          <div
            className={`panel ${activeIndex === index ? 'active' : ''}`}
          >
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomePageFAQ;