import React from 'react';
import BlogSide from '../../components/blogside';


function BlogTemplate() {
  return (
    <body className='blogpage'>
      <div className='bloggrid'>
        <div className='blogmain'>
          <article>
          <h1><strong>Title</strong></h1>

          <h2> <em>"Sub-title"</em></h2>


          <p><em>Published 11/8/24 - Last Edited 11/8/24</em></p>



        </article>
        </div>
        <BlogSide></BlogSide>
      </div>
    </body>
  );
}

export default BlogTemplate;
