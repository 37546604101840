import React from 'react';
import GameCards from '../components/gameCard';
import HomepageFeatures from '../components/homepagefeatures';
import UISeperator from '../components/UISeperator';
import HomePageFAQ from '../components/homepageFAQ';
import MiniUISeperator from '../components/miniUISeperator';
import BlobBlur from '../components/blobBlur';
import { Helmet } from 'react-helmet';



function Pricing() {
  return (
    <body className='pricingpage'>
      <Helmet>
          <title>Pricing - Results First, Pay Later</title>
          <meta name="description" content="Get a high-converting website that grows your business for only $49.99/month! Our expert design team crafts custom websites built to capture leads, convert sales, and scale with ease. Start now and launch a powerful, professional site that attracts your ideal clients—risk-free!" />
          <link rel="canonical" href="https://www.calucid.com/pricing" />
      </Helmet>
      <br></br><br></br>
      <h1>Pricing</h1>
      <h2><em>Win at the website game!</em></h2>
      <br></br><br></br>
      <br></br><br></br>
      {/* <h2 className='aboutpagetitle'>What Brings You In?</h2>
      <MiniUISeperator></MiniUISeperator>
      <p className='aboutpageparagraph'>Is your website failing to generate leads or engage your audience? Many businesses struggle with websites that look great but don’t convert. You need more than just a digital brochure—you need a site that actively drives results and grows your business.</p>
      <br></br>
      <UISeperator></UISeperator> */}
      <br></br><br></br>
      <h2 className='aboutpagetitle'>You Need a Killer Website.</h2>
      <MiniUISeperator></MiniUISeperator>
      <p className='aboutpageparagraph'>If you run a small business, and you don’t have a killer website, you’re <strong>leaving money on the table</strong>. In today’s digital world, a website isn’t just a “nice-to-have”—it’s your <strong>#1 salesperson</strong> working 24/7. It’s the face of your business, the first impression you make on potential customers, and the difference between them choosing you… or someone else.</p>
      <br></br>
      <UISeperator></UISeperator>
      <h2 className='aboutpagetitle'>Results First, Pay Later</h2>
      <MiniUISeperator></MiniUISeperator>
      <h2 className='aboutpageparagraph'>At Calucid, we believe in earning your trust first. That’s why we’ll build your website for free—no upfront costs, no obligations. Once you review the final product, if it’s not exactly what you want, you pay nothing. But if you love it (and we’re confident you will), we’ll launch your site online, and only then will you start paying—after we’ve created your website. </h2>
      <UISeperator></UISeperator>
      <HomepageFeatures></HomepageFeatures>
      <UISeperator></UISeperator>
      <h1>One Plan, One Price</h1>
      <GameCards></GameCards>
      <a style={{textDecoration:"none", position:"relative"}} href="/buy"><div class="block">Get Started</div></a>
      <UISeperator></UISeperator>
      <HomePageFAQ></HomePageFAQ>
      <UISeperator></UISeperator>
      <br></br><br></br>
      <h2 className='aboutpagetitle'>Aim For More Than Average.</h2>
      <MiniUISeperator></MiniUISeperator>
      <p className='aboutpageparagraph'>Let’s face it: most web design companies churn out cookie-cutter websites that look like glorified business cards. You deserve more than a basic page that collects dust; you need a dynamic online presence that reflects your brand and converts visitors into customers. While others settle for mediocre, we’re here to elevate your digital experience and ensure your website works as hard as you do.</p>
      <br></br>
      <UISeperator></UISeperator>
      <h2 className='aboutpagetitle'>Best Descision you'll make all year.</h2>
      <MiniUISeperator></MiniUISeperator>
      <p className='aboutpageparagraph'>With are satisfaction guarentee, there is no reason to say no.</p>
      <br></br>
      <BlobBlur></BlobBlur>

    </body>
  );
}

export default Pricing;
