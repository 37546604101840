import React from 'react';
import '../styles.css';
import GameCards from './gameCard';


function HomePagePricing(){
  return (
    <div>
      <h1>Pricing</h1>
      <div>
        <GameCards></GameCards>
      </div>
      <a style={{textDecoration:"none", position:"relative"}} href="/buy"><div class="block">Get Started</div></a>
      </div>
  );
}

export default HomePagePricing;