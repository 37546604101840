import React from 'react';
import '../styles.css';
import GivingBackButton from './givingBackButton';
import HomepageFeaturesCard from './homepagefeaturescard';


function HomepageFeatures(){
  return (
    <div>
      <h1>Features</h1>
    <div  className='featurescontainer'>
      <HomepageFeaturesCard img={"/images/computerIcon.webp"} title={"Custom Web Design"} description={"Every website we create is designed to make your brand stand out. Our team engineers custom sites that capture attention, boost engagement, and drive conversions. If you want a site that looks great and delivers results, you're in the right place"}></HomepageFeaturesCard>
      <HomepageFeaturesCard title={"Responsive Web Design"} description={"We deliver flawless user experiences on all devices. With responsive designs that adapt to any screen, your website will exceed expectations. From planning to development, we ensure a seamless, engaging experience for every visitor, whether on desktop, tablet, or mobile. Consistency is key, and we make sure your site performs on every platform."}></HomepageFeaturesCard>
      <HomepageFeaturesCard title={"Search Engine Optimization"} description={"We provide a full range of services to boost your website’s ranking and attract organic, non-paid Google traffic. Our team conducts a thorough SEO audit, offering actionable insights and tailored strategies to improve your visibility. With our approach, you'll see real, measurable gains in search rankings and traffic."}></HomepageFeaturesCard>
      <HomepageFeaturesCard title={"Fast Delivery"} description={"With our 7-day delivery, you can have your dream website in less than a week. We build websites quickly and efficiently. While others may take weeks, we work around the clock to deliver a high-converting, fully optimized site on time. No delays, no excuses. If you're ready to launch and make an impact, we’ll get it done faster than anyone else."}></HomepageFeaturesCard>
    </div>
    </div>
  );
}

export default HomepageFeatures;