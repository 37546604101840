import React from 'react'; 
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../styles.css';

function GivingBackButton({ title, description, link }) {  // Add link as a prop
  return (
    <Link to={link} className='givingbackbuttons'>  {/* Use Link to wrap the content */}
      <h2>{title} ➚</h2>
      <p>{description}</p>
    </Link>
  );
}

export default GivingBackButton;
