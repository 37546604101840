import React from 'react';
import GivingBack from '../components/givingBack';
import UISeperator from '../components/UISeperator';
import MiniUISeperator from '../components/miniUISeperator';
import BlobBlur from '../components/blobBlur';
import { Helmet } from 'react-helmet';


function About() {
  return (
    <body className='aboutpage'>
      <Helmet>
          <title>About - Calucid</title>
          <meta name="description" content="Founded in 2024, Calucid is a dynamic website design company dedicated to bringing digital visions to life with cutting-edge design and seamless functionality. We specialize in responsive web design, custom development, and search engine optimization, creating stunning, user-friendly websites tailored to each brand. Whether launching a new business or enhancing an established one, Calucid provides the expertise and innovative strategies to build a powerful online presence that drives results and leaves a lasting impression." />
          <link rel="canonical" href="https://www.calucid.com/about" />
      </Helmet>
      <br></br><br></br>
      <h1>About Calucid</h1>
      <br></br><br></br>
      <br></br><br></br>

      <h2 className='aboutpagetitle'>Who Are We?</h2>
      <MiniUISeperator></MiniUISeperator>
      <p className='aboutpageparagraph'>Founded in 2024, Calucid is a dynamic website design company dedicated to bringing digital visions to life with cutting-edge design and seamless functionality. We specialize in responsive web design, custom development, and search engine optimization, creating stunning, user-friendly websites tailored to each brand. Whether launching a new business or enhancing an established one, Calucid provides the expertise and innovative strategies to build a powerful online presence that drives results and leaves a lasting impression.</p>
      <br></br><br></br>

      <UISeperator></UISeperator>

      <h2 className='aboutpagetitle'>What We Believe In.</h2>
      <MiniUISeperator></MiniUISeperator>
      <p className='aboutpageparagraph'>We don’t just work for clients—we partner with them to drive real growth. Our focus? Helping brands break through the noise and dominate their space with killer web design and digital strategies. And we don’t nickel-and-dime you—we give away powerful tools for free because we’re in this for the long game. Our goal is simple: help you win. Plus, we know balance matters. That’s why we live out our values, deliver results, and have fun while doing it.</p>
      <br></br>
      <br></br><br></br><br></br><br></br>

    <div className='contactsocialssection'>
      <h1>Follow Us on Social Media</h1>
      <MiniUISeperator></MiniUISeperator>
      <div>
      <a style={{backgroundImage:'url("/images/xiconblack.png")'}} href='https://x.com/calucidofficial' className='socialsicon'></a>
      <a style={{backgroundImage:'url("/images/black-instagram-icon.webp")'}} href='https://instagram.com/calucidofficial' className='socialsicon'></a>
      </div>
    </div>

      <GivingBack></GivingBack>

      <h1>Contact Us</h1>
      <h3>Send us an email: <a style={{color:'cyan'}} href="mailto:hello@calucid.com">hello@calucid.com</a></h3>

      <UISeperator></UISeperator>

      <h2 className='aboutpagetitle'>Are You a Business Owner?</h2>
      <h2>Than you've come to the right place.</h2>
      <br></br><br></br><br></br><br></br><br></br><br></br>
      <BlobBlur></BlobBlur>
    </body>
  );
}

export default About;
