import React from 'react';
import WebsiteDesignForm from '../components/websitedesignform';
import { ChakraProvider } from '@chakra-ui/react';



function Buy() {
  return (
    <body style={{backgroundColor:"#1a202c", padding:"50px"}}>
      <ChakraProvider>
        <WebsiteDesignForm></WebsiteDesignForm>
      </ChakraProvider>
    </body>
  );
}

export default Buy;
