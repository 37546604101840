import React from 'react';
import '../GameCards.css';

const GameCard = ({ color, imageSrc, imageSrc2}) => (
  <div className="game-card" data-color={color}>
    <div>
    <img className="game-card-front-image game-card-image" src={imageSrc2} alt={`${color} game`} />
    </div>
    <div className="game-card-faders">
      {[...Array(8)].map((_, index) => (
        <img key={index} className="game-card-fader game-card-image" src={imageSrc} alt={`${color} game fader`} />
      ))}
    </div>
  </div>
);

const GameCards = () => {
  const cards = [
    { color: "blue", imageSrc: "https://cdn.pixabay.com/photo/2019/10/18/01/45/city-4558069_1280.jpg", imageSrc2:"/images/pricing.png"  }
  ];

  return (
    <div className="game-cards-container">
      <div className="game-cards-wrapper">
        <a href='/buy' id="game-cards">
          {cards.map((card, index) => (
            <GameCard key={index} {...card} />
          ))}
        </a>
        
      </div>
    </div>
  );
};

export default GameCards;