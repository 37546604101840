// CollectionCount.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';



// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDz4KeNIcdmLwRuKLOes-7NN7wBkLJAMYQ",
    authDomain: "calucid-6d287.firebaseapp.com",
    projectId: "calucid-6d287",
    storageBucket: "calucid-6d287.appspot.com",
    messagingSenderId: "526773370648",
    appId: "1:526773370648:web:7ecf61233514bb74b1286d",
    measurementId: "G-JLHGWWC373"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);


  const CollectionCount = () => {
    const [docCount, setDocCount] = useState(null);
    const [loading, setLoading] = useState(true);
  
    // Hardcode the collection name here
    const collectionName = "websiteDesignRequests";
  
    useEffect(() => {
      const fetchCollectionCount = async () => {
        setLoading(true);
        try {
          // Reference to the collection
          const colRef = collection(db, collectionName);
          // Get all documents
          const colSnapshot = await getDocs(colRef);
          // Set the document count
          setDocCount(colSnapshot.size);
        } catch (error) {
          console.error("Error fetching document count:", error);
          setDocCount(0); // Set to 0 or handle error appropriately
        } finally {
          setLoading(false);
        }
      };
  
      fetchCollectionCount();
    }, []);
  
    return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <h1 style={{textAlign:"center"}}>{docCount}</h1>
        )}
      </div>
    );
  };
  
  export default CollectionCount;
