import React from 'react';
import '../styles.css';
import GivingBackButton from './givingBackButton';


function HomepageFeaturesCard({ title, description, img}){
  return (
    <div className='featurescard'>
      <div className='minifeaturescontainer'>
      {/* <div className='featurescardlogo'  style={{ backgroundImage: `url(${img})`}}></div> */}
      <div className='featurescardtitle'>{title}</div>
      </div>
      <div className='featurescardtext'>{description}</div>
    </div>
  );
}

export default HomepageFeaturesCard;