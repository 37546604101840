import React from 'react';
import BlogCard from '../components/blogCard';
import '../styles.css';
import { Helmet } from 'react-helmet';



function Blog() {
  return (
    <body className='blogmenu'>
      <Helmet>
          <title>Calucid's Business Blog</title>
          <meta name="description" content="Your #1 resource for website design, SEO, digital marketing tips, and business advice to help you build a successful online business." />
          <meta name="keywords" content="entrepreneurship, business tips, website development, digital marketing, SEO strategies, startup advice, online business growth, small business marketing, social media marketing, content marketing, search engine optimization, building a brand, website design for startups, digital marketing trends, blogging for business, SEO for beginners, ecommerce marketing, online branding, business growth strategies, lead generation, PPC advertising, email marketing tips, scaling a business, web analytics, conversion rate optimization, entrepreneurship blog"/>
          </Helmet>
      <h1>Calucid's Business Blog</h1>
      <div className='blogcontainer'>
        <BlogCard link="/blog/high-converting-website-can-10x-your-business" image="https://media.gettyimages.com/id/1398198328/video/profit-chart-symbol.jpg?s=640x640&k=20&c=AJZW8qsZ08bExOQG-anTeJVv4bSaHWFMwb6cSSDhEDE=" title="How a High-Converting Website Can 10x Your Business: The No-B.S. Guide" date="Nov 15, 2024"></BlogCard>
        <BlogCard link="/blog/website-design-terms" image="https://cdn.pixabay.com/photo/2015/01/27/16/03/dictionary-613910_1280.jpg" title="Understanding Key Website Design Terms: A Guide for Buyers" date="Nov 9, 2024"></BlogCard>
        <BlogCard link="/blog/7-essentials-for-a-website" image="https://cdn.pixabay.com/photo/2018/01/26/09/07/hand-3108158_1280.jpg" title="7 Essential Elements for a User-Friendly Website Design" date="Nov 15, 2024"></BlogCard>
        <BlogCard link="/blog/seo-simplified" image="https://www.tales.co.nz/wp-content/uploads/sites/62/2019/10/3-Pillars-01-1024x785.jpg" title="SEO Simplified: The Three Pillars of SEO" date="Nov 8, 2024"></BlogCard>
        <BlogCard link="/blog/steve-jobs-design-philosophy" image="https://ioannaladopoulou.design/wp-content/uploads/2019/01/3DD0B6CD-CFD2-434F-9D47-7796CCC812A9-1024x738.jpeg" title="Steve Jobs’ Design Philosophy: The Relentless Pursuit of Simplicity" date="Nov 8, 2024"></BlogCard>
        {/* <BlogCard link="/blog/seo-for-developers" image="https://i.ytimg.com/vi/-B58GgsehKQ/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLACvjdUsOs0Re-7HsUViZLMDC48OA" title="Pillar 1: SEO For Developers"></BlogCard>
        <BlogCard link="/blog/seo-content" image="https://www.webstyle.com/wp-content/uploads/sites/8/SEO-4.jpg" title="Pillar 2: Optimize Content for SEO"></BlogCard>
        <BlogCard link="/blog/seo-backlinks" image="https://madhawks.com/public/uploads/blog/The%20Role%20of%20Backlinks%20in%20Modern%20SEO.jpg" title="Pillar 3: SEO Backlinks"></BlogCard>
        */}       
        




      </div>
      <br></br><br></br><br></br><br></br>
      <br></br><br></br><br></br><br></br>

    </body>
  );
}

export default Blog;
