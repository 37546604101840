import React from 'react';
import '../styles.css';
import GivingBackButton from './givingBackButton';


function GivingBack(){
  return (
    <div className='givingBack'>
      <h1>We Believe in Giving Back</h1>
      <h3>We want to help businesses thrive. That’s why we provide the resources to unlock your full potential. Let’s succeed together!</h3>
      <div className='givingBackContainer'>
      <GivingBackButton link="/blog" title="Calucid's Business Blog" description="Your #1 resource for website design, SEO, digital marketing tips, and business advice." />
      <GivingBackButton link="/tools/websitegrader" title="Website Design Grader" description="Test your website's speed, content and more with our all in one website grader" />
      {/* <GivingBackButton link="/tools/seoaudit" title="SEO Audit Tool" description="We are committed to making a positive impact in our community." /> */}
      {/* <GivingBackButton title="Calucid's Online Business Roadmap" description="We are committed to making a positive impact in our community." /> */}

        
      </div>
    </div>
  );
}

export default GivingBack;