import React from 'react';



function PrivacyPolicy() {
  return (
<body style={{ padding: '20px' }}>
<h1>Privacy Policy</h1>

<p>At Calucid, we are committed to protecting the privacy of our clients and website visitors. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our web design services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>

<h2>1. Information We Collect</h2>
<p>We may collect and process the following information:</p>

<ul>
    <li><strong>Personal Information:</strong> Information such as your name, email address, phone number, company name, and billing details collected when you contact us, sign up for services, or make a purchase.</li>
    <li><strong>Non-Personal Information:</strong> Information such as browser type, operating system, and IP address, which we automatically collect to enhance user experience on our website.</li>
    <li><strong>Third-Party Data:</strong> Information received from third-party sources, such as analytics providers or social media platforms, as permitted by their terms and your settings.</li>
</ul>

<h2>2. How We Use Your Information</h2>
<p>We use the information we collect for various purposes, including:</p>

<ul>
    <li><strong>Providing and Managing Services:</strong> To create and manage your account, process payments, and provide the website design services you request.</li>
    <li><strong>Improving Our Services:</strong> To better understand how visitors use our website and services, and to enhance user experience.</li>
    <li><strong>Communication:</strong> To contact you about service updates, special offers, and important notices related to our services.</li>
    <li><strong>Legal Compliance:</strong> To comply with applicable laws, regulations, and legal obligations.</li>
</ul>

<h2>3. Information Sharing and Disclosure</h2>
<p>We may share your information in the following circumstances:</p>

<ul>
    <li><strong>Service Providers:</strong> With third-party vendors who assist us in providing our services, such as payment processors and marketing agencies.</li>
    <li><strong>Legal Obligations:</strong> When required to do so by law or to protect the rights, property, or safety of Calucid, our clients, or others.</li>
    <li><strong>Business Transfers:</strong> If we undergo a business transaction, such as a merger or acquisition, your information may be transferred as part of the assets.</li>
</ul>

<h2>4. Your Rights and Choices</h2>
<p>You have rights regarding your personal data and may exercise the following options:</p>

<ul>
    <li><strong>Access and Correction:</strong> Request access to or correction of your personal information.</li>
    <li><strong>Opt-Out:</strong> Choose to stop receiving marketing emails by clicking the unsubscribe link in any email we send or contacting us directly.</li>
    <li><strong>Data Deletion:</strong> Request the deletion of your personal information, subject to legal and contractual obligations.</li>
</ul>

<h2>5. Cookies and Tracking Technologies</h2>
<p>We use cookies and similar technologies to enhance your browsing experience, analyze website traffic, and personalize content. You can manage your cookie preferences through your browser settings.</p>

<h2>6. Data Retention</h2>
<p>We retain personal data only as long as necessary to fulfill the purposes for which it was collected, or as required by law. When data is no longer needed, we will securely delete or anonymize it.</p>

<h2>7. Data Security</h2>
<p>We implement technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>

<h2>8. International Data Transfers</h2>
<p>Your information may be transferred to and processed in countries outside of your own. We take steps to ensure your data is protected in compliance with applicable laws, such as using Standard Contractual Clauses for data transfers.</p>

<h2>9. Children’s Privacy</h2>
<p>Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware of such data, we will take steps to delete it.</p>

<h2>10. Changes to This Privacy Policy</h2>
<p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, with an updated revision date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>

<h2>11. Contact Us</h2>
<p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>

<p>
    <strong>Email:</strong> <a href="mailto:hello@calucid.com">hello@calucid.com</a>
</p>

<p><em>Last updated: 11/01/2024</em></p>

</body>

  );
}

export default PrivacyPolicy;
