import React from 'react';
import '../styles.css';


function UISeperator(){
  return (
    <div className='UISeperator'>
    </div>
  );
}

export default UISeperator;