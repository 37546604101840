import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../styles.css';

function BlogCard({ title, image, link, date }) {
  return (
    <Link to={link} className='blogcard-link'>
      <div style={{ backgroundImage: `url(${image})` }}  className='blogcard'>
        <div className='blogcardbottom'>
        <h2 className='blogcarddate'>{date}</h2>
          <h2 className='blogcardtitle'>{title}</h2>
        </div>
      </div>
    </Link>
  );
}

export default BlogCard;
