import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AhrefBacklinkChecker = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirects the user to the ahrefs backlink checker page
    window.location.href = 'https://ahrefs.com/backlink-checker';
  }, [navigate]);

  return null; // No need to render anything
};

export default AhrefBacklinkChecker;
