import React, { useState } from 'react';
import '../styles.css';
import MiniUISeperator from './miniUISeperator';




function BlogSide(){

  const [inputValue, setInputValue] = useState('');

  const handleAnalysis = () => {
      if (!inputValue) {
      return; // Don't redirect if input is empty
      }

      // Encode the URL parameter
      const encodedUrl = encodeURIComponent(inputValue);
      
      // Construct the new URL with the encoded parameter
      // This will redirect to /analysis with the URL parameter
      window.location.href = `/tools/websitegrader?url=${encodedUrl}`;
  };


  return (
    <div className='blogside'>
        <div className='blogsideabout'>
            <h3><b>About Us</b></h3>
            <div>At Calucid, we are a premier web design and development agency focused on creating custom, responsive websites that are visually stunning and SEO-optimized. Our expert team delivers innovative solutions, ensuring fast load times and excellent user experiences. We provide comprehensive services, including web design, development, SEO optimization, and maintenance. Let us help you establish a powerful online presence that drives engagement and growth.</div>
        </div>
        <div className='blogsidesocial'>
            <div>
            <a style={{backgroundImage:'url("/images/xiconblack.png")'}} href='https://x.com/calucidofficial' className='socialsicon'></a>
            <a style={{backgroundImage:'url("/images/black-instagram-icon.webp")'}} href='https://instagram.com/calucidofficial' className='socialsicon'></a>
            </div>
        </div>
        <div className='blogsidetool'>
            <p>Want to test your website for free?</p>
            <MiniUISeperator></MiniUISeperator>
            <div style={{display:"flex", justifyContent:"center", marginTop:"30px", marginBottom:"10px"}}>
                        <input 
                        placeholder="https://example.com"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleAnalysis();
                        }
                        }}
                    />
                    <button onClick={handleAnalysis}>Start My Analysis</button>
            </div>
            
        </div>
    </div>
  );
}

export default BlogSide;