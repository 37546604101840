import React from 'react';



function Terms() {
  return (
<body style={{ padding: '20px' }}>

<div>
    <h1>Terms of Service</h1>

    <p>Welcome to Calucid! These Terms of Service ("Terms") govern your access to and use of our website design services, including any updates, support, and other services provided by Calucid. By subscribing to our service, you agree to these Terms in full. If you do not agree, please refrain from using our services.</p>

    <h2>1. Services Provided</h2>
    <p>For a monthly subscription fee of $49.99, Calucid provides the following services:</p>
    <ul>
        <li><strong>Custom Design:</strong> Unique, professionally designed websites tailored to your specifications.</li>
        <li><strong>Responsive and Mobile-Friendly Design:</strong> Sites that adapt to both desktop and mobile devices.</li>
        <li><strong>SEO Optimization:</strong> Basic search engine optimization to enhance your site's visibility.</li>
        <li><strong>7-Day Delivery:</strong> Website delivery within seven business days of the initial consultation or specification confirmation.</li>
        <li><strong>Management & Updates:</strong> Regular management and updates, including content changes, as part of our ongoing service.</li>
        <li><strong>Domain Included:</strong> We provide a domain name as part of the subscription.</li>
        <li><strong>Satisfaction Guarantee:</strong> Full refund available within the first 30 days if you are unsatisfied with the service (see Section 5).</li>
    </ul>

    <h2>2. 7-Day Delivery Guarantee</h2>
    <p>We guarantee that your website will be delivered within seven business days, provided we receive all required content, preferences, and instructions within a reasonable time frame. If we do not meet the 7-day delivery guarantee due to delays on our part, your first month’s fee will be waived.</p>

    <h2>3. Payment and Subscription</h2>
    <ul>
        <li><strong>Monthly Fee:</strong> By subscribing, you agree to pay a recurring monthly fee of $49.99. This fee covers the full range of services described in Section 1.</li>
        <li><strong>Billing Cycle:</strong> The billing cycle starts on the date of your first payment and recurs monthly.</li>
        <li><strong>Cancellation:</strong> You may cancel at any time by notifying us at least five business days before your next billing date to avoid being charged for the following month.</li>
    </ul>

    <h2>4. Ownership and Licensing</h2>
    <ul>
        <li><strong>Company Rights:</strong> Calucid retains ownership of all website designs, content, and underlying code. Your monthly subscription grants you a non-exclusive, revocable license to use the website during the active period of your subscription.</li>
        <li><strong>Termination of License:</strong> Upon cancellation or termination of the subscription, the license to use the website is immediately revoked, and we reserve the right to take down or disable access to the site.</li>
    </ul>

    <h2>5. Satisfaction Guarantee and Refunds</h2>
    <ul>
        <li><strong>30-Day Satisfaction Guarantee:</strong> If you are unsatisfied with the service, you may request a full refund within 30 days of the initial purchase.</li>
        <li><strong>Refund Conditions:</strong> To qualify for a refund, requests must be made in writing via <a href="mailto:hello@calucid.com">hello@calucid.com</a>. Upon refund, all rights to the website design revert to Calucid, and the website will be taken offline or disabled.</li>
    </ul>

    <h2>6. Limitations of Liability</h2>
    <ul>
        <li><strong>Service Delivery:</strong> While we strive to provide timely and consistent services, Calucid is not liable for any interruptions or delays outside of our control.</li>
        <li><strong>Website Downtime:</strong> We are not responsible for losses incurred due to website downtime or hosting interruptions caused by factors outside of our management or control.</li>
    </ul>

    <h2>7. Modifications to Terms</h2>
    <p>We reserve the right to modify these Terms at any time. Changes to the Terms will be posted on our website and become effective 30 days after posting. Continued use of our services after such changes implies acceptance of the updated Terms.</p>

    <h2>8. Contact Information</h2>
    <p>For any questions, concerns, or support requests, please contact us at:</p>
    <div>
        <p><strong>Email:</strong> <a href="mailto:hello@calucid.com">hello@calucid.com</a></p>
        <p><strong>Website:</strong> <a href="https://calucid.com" target="_blank">calucid.com</a></p>
    </div>
    <p><em>Last updated: 11/01/2024</em></p>

    <p>By subscribing to our service, you acknowledge that you have read, understood, and agree to these Terms.</p>
</div>

</body>

  );
}

export default Terms;
